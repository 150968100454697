import '../node_modules/photoswipe/dist/photoswipe.css';
import '../node_modules/photoswipe/dist/default-skin/default-skin.css';
import './fonts/stylesheet.css';
import 'swiper/swiper-bundle.css';
import './tailwind.css';
import './main.scss';


import TomSelect from 'tom-select/dist/esm/tom-select';
import 'tom-select/dist/esm/plugins/remove_button/plugin';
import '../node_modules/tom-select/dist/css/tom-select.min.css';

import Swiper from 'swiper/bundle';
import initPhotoSwipeFromDOM from './js/photoswipe.js';
import CookiesEuBanner from 'cookies-eu-banner';
// configure Swiper to use modules
import Alpine from 'alpinejs'
import 'htmx.org';

window.Alpine = Alpine

var banner = new CookiesEuBanner(function(){
  var _paq = window._paq = window._paq || [];
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  (function() {
    var u="//stats.zauberberg-medien.de/";
    _paq.push(['setTrackerUrl', u+'e9f22298ea38.php']);
    _paq.push(['setSiteId', '1']);
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src=u+'e9f22298ea38.js'; s.parentNode.insertBefore(g,s);
  })();

});

window.toggleExtraFieldChecked = function (initial, condition) {
  return {
    value: initial,
    isOpen() { return condition(this.value); },
    init() {
      if (Array.isArray(initial)) {
        this.value = [...this.$el.querySelectorAll('[x-model="value"]:checked')].map(node => node.value);
      } else {
        let node = this.$el.querySelector('[x-model="value"]:checked');
        if (node) {
          this.value = node.value;
        }
      }
    },
  }
}


const noop = () => {};

const requestTimeout = (fn, delay, registerCancel) => {
  const start = new Date().getTime();

  const loop = () => {
    const delta = new Date().getTime() - start;

    if (delta >= delay) {
      fn();
      registerCancel(noop);
      return;
    }

    const raf = requestAnimationFrame(loop);
    registerCancel(() => cancelAnimationFrame(raf));
  };

  const raf = requestAnimationFrame(loop);
  registerCancel(() => cancelAnimationFrame(raf));
};

window.rotateFeature = function(slot, data) {
  let cancel = noop;
  const registerCancel = fn => cancel = fn;

  return {
    slotURL: slot,
    defaultRotationTime: 20 * 1000,
    inView: false,
    data: data,
    init() {
      let checkFeatureInView = ([e]) => {
        this.inView = e.intersectionRatio > 0.3;
      };
      this.observer = new IntersectionObserver(checkFeatureInView, {threshold: 0.3});
      this.observer.observe(this.$el);
      this.play();
    },
    next() {
      fetch(this.slotURL).then(response => response.json()).then(data => {
        this.data = data
        fetch(this.data.impress).then();
      });
    },
    play() {
      console.log('play');
      if (this.inView) {
        console.log('next');
        this.next();
      }
      requestTimeout(() => this.play(), this.defaultRotationTime, registerCancel);
    }
  }
}

window.addEventListener('load', () => {
  Alpine.start();
});


if (document.getElementsByClassName('gallery-main').length) {
  var galleryThumbs = {};

  if (document.getElementsByClassName('gallery-thumbs').length) {
    galleryThumbs = {swiper: new Swiper('.gallery-thumbs', {
      spaceBetween: 10,
      slidesPerView: 2,
      freeMode: true,
      navigation: {
        nextEl: '.gallery-thumbs-next',
        prevEl: '.gallery-thumbs-prev',
      },
      loop: true,
      centeredSlides: false,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      slideToClickedSlide: true,
      preloadImages: false,
      freeModeSticky: true,
      // Enable lazy loading
      lazy: true,
      breakpoints: {
        // others are 640px, 768px, 1024px, 1280px see tailwind.config.js
        // TODO: import from tailwind
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
          direction: 'vertical',
        },
      }
    })};
  } else if (document.getElementsByClassName('gallery-thumbs-3x').length) {
    galleryThumbs = {swiper: new Swiper('.gallery-thumbs-3x', {
      spaceBetween: 10,
      slidesPerView: 2,
      freeMode: true,
      navigation: {
        nextEl: '.gallery-thumbs-next',
        prevEl: '.gallery-thumbs-prev',
      },
      loop: true,
      centeredSlides: false,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      slideToClickedSlide: true,
      preloadImages: false,
      freeModeSticky: true,
      // Enable lazy loading
      lazy: true,
      breakpoints: {
        // others are 640px, 768px, 1024px, 1280px see tailwind.config.js
        // TODO: import from tailwind
        768: {
          slidesPerView: 3,
          spaceBetween: 20,
          direction: 'vertical',
          centeredSlides: true,
        },
      }
    })};
  }

  var galleryTop = new Swiper('.gallery-main', {
    spaceBetween: 0,
    preloadImages: false,
    pagination: {
      el: '.gallery-main-indicator',
      type: 'fraction',
    },
    // Enable lazy loading
    lazy: true,
    thumbs: galleryThumbs,
  });

  initPhotoSwipeFromDOM('.gallery-main');


}



let renderTomSelect = (el) => {
  let settings = {};
  new TomSelect(el, settings);
}

document.querySelectorAll('.tom-select').forEach(renderTomSelect);

window.renderTomSelect = renderTomSelect;
